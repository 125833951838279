.showtags{
    max-height: 100%;
    -webkit-transition: max-height 0.25s ease-in, opacity 0.25s ease-in;
    -moz-transition: max-height 0.25s ease-in, opacity 0.25s ease-in;
    -ms-transition: max-height 0.25s ease-in, opacity 0.25s ease-in;
    transition: max-height 0.25s ease-in, opacity 0.25s ease-in;
    opacity: 1;
}
.hidetags{
    max-height: 0;
    -webkit-transition: max-height 0.25s ease-out, opacity 0.25s ease-out;
    -moz-transition: max-height 0.25s ease-out, opacity 0.25s ease-out;
    -ms-transition: max-height 0.25s ease-out, opacity 0.25s ease-out;
    transition: max-height 0.25s ease-out, opacity 0.25s ease-out;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.tag-border{
    border: 1px solid green;
    border-radius: 4px;
    min-height: 40px;
    padding: 12px;
}

.tag-container{
    button{
        margin-left: 4px;
        margin-top: 4px;
    }
}
