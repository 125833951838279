.wrapper{
    display: flex;
    width: 100%;
    align-items: stretch;
    > .container{
        padding-top: 14px;  
    }
    .pageContainer{
        // background: white;
        // border-radius: 3px;
        // padding: 10px;
        // box-shadow: 0px 0px 2px #888888;
        > .card{
            >.card-header{
                padding: 24px;
                background: none;
                /* desktop/large body strong */

                font-family: Open Sans;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 160%;
                /* identical to box height, or 29px */

                letter-spacing: 0.5px;

                /* NTN Main */

                color: #006937;
            }
        }
    }

   
}

.public-wrapper{
    display: flex;
    width: 100%;
    align-items: stretch;
}
.desktop-only{
    display: flex;
}
.mobile-only{
    display: none;
}

@media (max-width: 768px) {
    .wrapper{
       
    }

    .desktop-only{
        display: none;
    }
    .mobile-only{
        display: flex;
    }
}

.disabled-btn{
    cursor: not-allowed;
    pointer-events: none;

}