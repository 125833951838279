.footer-container{
    padding: 12px;
}
.footer-contact-text{
    font-size: 10px;
}

.ntn-list-navigations{
    font-size: 12px;
    display: block;
    ul li {
        position: relative;
      }
      
      ul {
        list-style: none;
      }
      
      ul li:before{
        content: "";
        position: absolute;
        border-right: 2px solid #f8f9fa;
        border-bottom: 2px solid #f8f9fa;
        width: 7px;
        height: 7px;
        top: calc(75% - 4px);
        left: -20px;
        transform: translateY(-50%) rotate(-45deg);
      }

      .ntn-devider{
        border-top: 1px solid #316550;
        margin-left: -20px;
        margin-top: 4px;
        margin-bottom: 4px;
      }
}