#sidebar.nav-menu {
  background-color: #0eb38c;
  min-width: 20%;
  min-height: calc(100vh);
}

#content{
  max-width: 100%;
}

.expanded {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: auto;
}
.collapsed {
  -moz-transition: height 0.5s;
  -ms-transition: height 0.5s;
  -o-transition: height 0.5s;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
  height: 0;
  overflow: hidden;
}

@media (max-width: 768px) {
  /* 80px and its content aligned to centre. Pushing it off the screen with the
       negative left margin
    */
  #sidebar.active {
    min-width: 240px;
    max-width: 240px;
    /* text-align: center; */
    margin-left: 0px !important;
    margin-left: 0px !important;
    position: absolute;
    z-index: 9;
    height: 100%;
    transition: 0.5s;
  }

  /* Reappearing the sidebar on toggle button click */
  #sidebar {
    min-width: 240px;
    max-width: 240px;
    /* text-align: center; */
    margin-left: -240px !important;
    height: 100%;
    z-index: 999999;
    transition: 0.5s;
    position: absolute;
  }

  /* Toggling the sidebar header content, 
       hide the big heading [h3] and showing the small heading [strong] and vice versa
    */
  #sidebar .sidebar-header strong {
    display: none;
  }
  #sidebar.active .sidebar-header h3 {
    display: none;
  }
  #sidebar.active .sidebar-header strong {
    display: block;
  }

  /* Downsize the navigation links font size */
  #sidebar.active ul li a {
    // padding: 10px 0px;
    font-size: 0.80em;
  }

  #sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
  }

  /* Adjust the dropdown links padding*/
  #sidebar.active ul ul a {
    // padding: 10px !important;
  }

  /* Changing the arrow position to bottom center position, 
      translateX(50%) works with right: 50% 
      to accurately  center the arrow */
  .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
  }
  .submenu-container {
    a {
      padding-left: 2rem !important;
    }
  }
  
  #content{
    max-width: 100%;
  }
}
// .nav-div {
//   > ul {
//     padding-top: 20px;
//   }
// }
