.close-btn-absolute-right{
    right: 0px;
    position: absolute;
    z-index: 1;
    top: 0px;
    color: #000;
    background-color: #fff;
}

.larger-image-container{
    overflow: hidden;
    min-height: 400px;
    img{
        max-width: 800px !important;
        transition: transform 0.25s ease;
        cursor: zoom-in;
    }

    input[type=checkbox] {
        display: none;
      }
      
      input[type=checkbox]:checked ~ label > img {
        transform: scale(2);
        cursor: zoom-out;
      }
}