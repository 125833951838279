.header {
  background: linear-gradient(270deg, #1c4375 34.05%, #0eb38c 81.37%);
  padding: 0px;
  margin: 0px;
  .logo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    .hamburger-menu-icon {
      color:#fff;
      font-size: 41px;
      padding-right: 13px;
      margin-left: -16px;
    }
  }
  .navbar-brand {
    padding: 0px;
  }

  .logo-size{
    width: 305px;
  }
}
.user-initials-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .user-initials {
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  .user-fullname {
    color: white;
  }
}
.navbar-nav {
  flex-direction: row;
  align-items: center;
  > a.nav-link {
    color: white !important;
    padding: 0px 8px;
    svg {
      height: 27px;
      width: 26px;
    }
  }
  .dropdown-menu {
    position: absolute;
  }
  .more-items {
   
    .dropdown-menu {
      margin-left: -144px !important;
    }
    svg{
      color: #fff;
    }
  }
  .profile-items {
    .dropdown-menu {
      margin-left: -24px !important;
    }
  }
}

.dropdown-toggle:after {
  display: none;
}

.badge-container {
  position: relative;
  > .badge {
    position: absolute;
    right: -5px;
    top: -11px;
    background: red;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50%;
    height: 22px;
    width: 22px;
    font-size: xx-small;
  }
}

.notification-popover {
  min-width: 400px;
  height: auto;
  // overflow: hidden;
  .popover-body {
    overflow: auto;
    height: auto;
  }
  .popover-header {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .header {
    .header-items {
      margin-right: 0px;
      .user-navigation {
        .username {
          display: none;
        }
      }

      .more-items {
        display: none;
      }

      > div {
        padding: 0px;
      }

      .user-more-navigation {
        display: block;
      }
    }

    .profile-items {
      .dropdown-menu {
        margin-left: -94px !important;
      }
    }

    .logo-size{
      width: 215px !important;
    }
  }
  .notification-popover {
    min-width: 100%;
  }
}
