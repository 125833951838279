.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: 20px;
  list-style: none;
  height: 50px;
  color: #f5f5f5;
  font-size: 16px;
  letter-spacing: 1px;

  &.active{
    color: #006937 !important;
    cursor: pointer;
    text-decoration: none;
    width: 250px;
    background: #ffffff;
    height: 30px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
    padding-top: 0px;
    margin-top: 20px;
  }
}


// .assessment-menu {
//   height: 10px;
// }
.nav-text:hover {
  text-decoration: none;
  color: #f5f5f5;
}

.nav-text-submenu:hover {
  color: #006937;
  cursor: pointer;
  text-decoration: none;
  width: 250px;
  background: #ffffff;
  height: 30px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.submenu-active {
  color: #006937 !important;
  cursor: pointer;
  text-decoration: none;
  width: 250px;
  background: #ffffff;
  height: 30px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
}

.nav-text a {
  text-decoration: none;
  width: 95%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}
.menu-items {
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > span > svg {
    margin-right: 15px;
  }
}
.nav-text-submenu {
  height: 30px;
  margin-top: 10px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 12px;
}

ul.components > li > ul > li > ul > li {
  a {
    padding-left: calc(2rem + 10px);
  }
}
