// Override default variables before the import
$body-bg: #f5f6f8;
$primary: #006937;
i,
em {
  font-style: italic !important;
}
b,
strong {
  font-weight: bolder !important;
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.wrapper {
  height: 100%;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.pageContainer {
  min-height: 100vh;
}
.form-label {
  margin-bottom: 0rem;
  font-size: 0.875rem;
}

.btn {
  font-weight: bold;
}
.default-color{
  color: var(--primary) !important;
}
.form-check-input:checked {
  background-color: var(--primary) !important;
  // border-color: var(--primary) !important;
}

.form-check-label {
  padding-left: 5px;
  padding-top: 1px;
}

.form-check-input[type="radio"] {
  height: 18px;
  width: 18px;
  accent-color: var(--primary) !important;
}

.radio-checkbox-size-40 {
  input {
    height: 40px !important;
    width: 40px !important;
  }
}
.custom-radio {
  &.form-check {
    padding-left: 0;
  }

  .option-heading{
    font-size: 22px;
    font-weight: bolder;
    color: #000;
    padding-right: 12px;
    font-family: monospace;
  }
  .form-check-input[type="radio"]:not(:checked) + label:before {
    border-radius: 50%;
    border: 2px solid #5a5a5a;
  }

  .form-check-input[type="radio"] + label:before,
  .form-check-input[type="radio"] + label:after {
    content: "";
    position: absolute;
    left: 0;
    // top: 0;
    margin: 4px;
    height: 18px;
    width: 18px;
    z-index: 0;
    transition: 0.28s ease;
  }
  .form-check-input:checked[type="radio"] + label:after {
    border-radius: 50%;
    border: 2px solid #26a69a;
    background-color: #26a69a;
    z-index: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  .form-check-input[type="radio"]:not(:checked),
  .form-check-input[type="radio"]:checked {
    position: absolute;
    left: -9999px;
    visibility: hidden;
  }

  .form-check-input[type="radio"]:not(:checked) + label,
  .form-check-input[type="radio"]:checked + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    transition: 0.28s ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .form-check-input[type="radio"]:checked + label:before {
    border-radius: 50%;
    border: 2px solid #26a69a;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: 0em;
  height: 18px;
  width: 18px;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  background-color: #fff;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3E%3C/svg%3E");
}

.form-check-input:checked:disabled[type="checkbox"] {
  background-color: #00693775 !important;
}

.form-check-input[type="checkbox"][readonly] {
  pointer-events: none;
  opacity: 0.5;
}

span[readonly]{
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.5;
}

.tts-speaker{
  cursor: pointer;
}

.badge {
  color: white;
}

.align-center-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.align-center-right-display-column{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.align-start-right {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.align-stretch-start-right {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.align-center-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.align-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align-center-space-evenly {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-y {
  overflow-y: auto;
}

.align-center-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-space-between{
  justify-content: space-between;
}
.align-end-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-align-end-right {
  display: flex;
  justify-content: flex-end;
}

.flex-direction-coulmn {
  flex-direction: column;
}

.display-flex-direction-row {
  display: flex;
  flex-direction: row;
}
.pl-5\.5{
  padding-left: 3.5rem !important;
}
.pr-5\.5{
  padding-right: 3.5rem !important;
}
.px-5\.5{
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}
.display-inline-flex{
  display: inline-flex;
}
.verical-icon-button {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.46rem;
}
.no-padding-margin{
  padding: 0px !important;
  margin: 0px !important;
}
.align-start {
  align-items: flex-start;
}
.align-baseline {
  align-items: baseline;
}
.size-28 {
  height: 28px;
  width: 28px;
}
.size-26 {
  height: 26px;
  width: 26px;
}

.size-24 {
  height: 24px;
  width: 24px;
}
.size-32 {
  height: 32px;
  width: 32px;
}
.size-150 {
  height: 150px;
  width: 150px;
}
.size-50 {
  height: 50px;
  width: 50px;
}
.width-50 {
  width: 50px;
}

.width-100 {
  width: 100px !important;
}

.width-150 {
  width: 150px !important;
}

.width-170 {
  width: 170px !important;
}
.width-200 {
  width: 200px !important;
}
.min-width-200 {
  min-width: 200px !important;
}
.min-width-165 {
  min-width: 165px !important;
}

.min-width-670 {
  min-width: 670px !important;
}
.width-90 {
  width: 90px !important;
}

.width-96 {
  width: 96px !important;
}

.width-29 {
  width: 29px;
}

.size-20 {
  width: 20px;
  height: 20px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-12{
  font-size: 12px;
}
.font-size-11{
  font-size: 11px;
}
.display-flex {
  display: flex;
}

.list-action-icon{
  color: #006937;
  cursor: pointer;
  
}
.display-inline {
  display: inline-block;
}

.text-color-primary {
  color: var(--primary);
}

.text-underline {
  text-decoration: underline !important;
}
.text-color-red {
  color: var(--red);
}

.text-color-black {
  color: var(--dark);
}

.rounded-bg {
  background-color: lightgray;
  border-radius: 50%;
}

button {
  svg {
    height: 18px;
    width: 18px;
  }
}

.input-group-text {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

a.link {
  color: #0056b3;
}

.input-error {
  input {
    border-color: #dc3545 !important;
    padding-right: calc(1.5em + 0.75rem) !important;
  }
}

.rounded-btn {
  border-radius: 150px !important;
}

.rounded-btn-20 {
  border-radius: 20px !important;
}

.margin-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.coulmn-size-150 {
  width: 150px;
}

.coulmn-size-170 {
  width: 170px;
}

.coulmn-size-180 {
  width: 180px;
}

.coulmn-size-190 {
  width: 190px;
}

.coulmn-size-130 {
  width: 130px;
}

.coulmn-size-120 {
  width: 120px;
}

.coulmn-size-200 {
  width: 200px;
}

.coulmn-size-250 {
  width: 250px;
}

.coulmn-size-50 {
  width: 50px;
}

.coulmn-size-60 {
  width: 60px;
}

.coulmn-size-70 {
  width: 70px;
}

.coulmn-size-80 {
  width: 80px;
}

.coulmn-size-90 {
  width: 90px;
}

.coulmn-size-100 {
  width: 100px;
}

.coulmn-size-110 {
  width: 110px;
}
.coulmn-break {
  word-break: break-word;
}

.coulmn-break {
  word-break: break-word;
}

.margin-top-200 {
  margin-top: 200px;
}

.width-250 {
  width: 250px;
}
.width-305 {
  width: 305px;
}
// button varients
.btn-blue {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.no-border-table{
  td{
    border-width:0px;
  }
}

.table-align-center{
  margin-left: auto;
  margin-right: auto;
}

.question-image-container{
  img{
    max-width: 200px;
    cursor: pointer;
  }
}

.note-text{
  font-size: x-small;
}

.header-icon{
  .up-arrow-action{
    cursor: pointer;
  }
  .down-arrow-action{
    cursor: pointer;
  }
}

.hide-element{
  display: none;
}
.pseudo-link{
  color: #0056b3;
  text-decoration: underline;
  cursor: pointer;
}

// mobile device
@media (max-width: 768px) {
  .assessment-header{
    font-size: 0.90rem;
  }
  .dropdown-menu{
    .dropdown-item{
      font-size: 0.90rem !important;
    }
  }
}

/****************************** public pages ****************/
.no-border-container {
  border: none;
  border-radius: 0px;
}

.public-footer-container{
  background-color: #255641;
}

.light-green-btn{
  background-color: #61B17D;
}

.mt-0\.5{
  margin-top: 2px;
}

.mt-0\.6{
  margin-top: 3px;
}

@media (min-width: 1240px) and (max-width: 1403px) {
  .table-responsive{
    max-width: 939px;
  }
}

.ttsplayer-popover{
  max-width: 366px;
}

@media (max-width: 768px) {
  .ttsplayer-popover{
    min-width: 350px;
  }
}


.small-text-color-primary{
  color: #006937;
  font-style: italic;
  font-size: smaller;
}