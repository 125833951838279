.ui-loader{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #eeeeee8a;
    z-index: 999999;
    text-align: center;
    .ui-loader-container{
        display: inline-block;
        position: relative;
        top: 35%;
    }
}